<template>
    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <div class="">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <h2 class=" mb-1"> {{ translations?.project_identifications }}</h2>
                            </div>
                        </div>
                    </div>
                    <div class="my-3">
                        <form ref="resetForm" @submit.prevent="formAction(data.inputFormData)">
                            <div class="row">
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div class="new-template-form">
                                        <div class="form-group mb-3">
                                            <label class="form-control-label mb-1">{{ translations?.project_goal }}</label>
                                            <textarea class="form-control" rows="2" v-model="data.inputFormData.project_goal"
                                                   name="project_goal"></textarea>
                                        </div>
                                        <div class="form-group mb-3">
                                            <label class="form-control-label mb-1">{{ translations?.project_scope }}</label>
                                            <textarea class="form-control" rows="2" v-model="data.inputFormData.project_scope"
                                                   name="project_scope" ></textarea>
                                        </div>
                                        <div class="form-group mb-3">
                                            <label class="form-control-label mb-1">{{ translations?.project_classification_methodology }}</label>
                                            <textarea class="form-control" rows="2" v-model="data.inputFormData.classification"
                                                   name="classification"></textarea>
                                        </div>
                                        <div class="form-group mb-3">
                                            <label class="form-control-label mb-1">{{ translations?.project_constraints_and_dependencies }} </label>
                                            <textarea class="form-control" rows="2" v-model="data.inputFormData.constraints_dependencies"></textarea>
                                        </div>
                                        <div class="form-group mb-3">
                                            <label class=" form-control-label mb-1">{{ translations?.project_opportunities_and_risks }} </label>
                                            <textarea class="form-control" rows="2" v-model="data.inputFormData.opportunities_risks"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div class="form-group mb-3" v-if="data.inputFormData.identification">
                                        <label class="form-control-label mb-1">{{ translations?.program_name }}</label>
                                        <input type="text" class="form-control" v-model="data.inputFormData.identification.program_name" :placeholder="translations?.program_name">
                                    </div>
                                    <div class="form-group mb-3" v-if="data.inputFormData.identification">
                                        <label class="form-control-label mb-1">{{ translations?.project_name }} </label>
                                        <input type="text" class="form-control" v-model="data.inputFormData.identification.project_name"
                                               :placeholder="translations?.project_name" name="project_name" >
                                    </div>
                                    <div class="form-group mb-3" v-if="data.inputFormData.identification">
                                        <label class="form-control-label mb-1">{{ translations?.product_name }}</label>
                                        <input type="text" class="form-control" v-model="data.inputFormData.identification.product_name"
                                               :placeholder="translations?.product_name" name="product_name" >
                                    </div>
                                    <div class="form-group mb-3" v-if="data.inputFormData.identification">
                                        <label class="form-control-label mb-1">{{ translations?.program_number }}</label>
                                        <input type="text" class="form-control" v-model="data.inputFormData.identification.program_number" :placeholder="translations?.program_number">
                                    </div>
                                    <div class="form-group mb-3" v-if="data.inputFormData.identification">
                                        <label class="form-control-label mb-1">{{ translations?.baseline }}</label>
                                        <input type="text" class="form-control" v-model="data.inputFormData.identification.baseline" :placeholder="translations?.baseline">
                                    </div>
                                    <div class="form-group mb-3" v-if="data.inputFormData.framework">
                                        <label class="form-control-label mb-1">{{ translations?.estimated_total_project_cost }}</label>
                                        <textarea class="form-control" rows="2" v-model="data.inputFormData.framework.project_cost"></textarea>
                                    </div>
                                </div>
                                <div v-if="projectStatus != 'Completed'" class="col-12">
                                    <div class="form-group m-t-md">
                                        <div class="d-flex justify-content-end" v-if="data.permission.canEdit">
                                            <button type="submit" class="btn btn-green"
                                                :disabled="invitationData.invitation_status === 'Invited' ? true : false"
                                            >{{ translations?.save }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import HelperFunction from "@/common/helpers";
    import {useRoute} from "vue-router";
    import {computed, onMounted} from "vue";
    import Index from "@/views/layouts/auth/Index";
    import {useStore} from "vuex";

    export default {
        name: "Project Identification",
        components: {Index},
        setup() {
            const { data, editFormData, storeFormData, permissionResolver } = HelperFunction();
            const route = useRoute()
            const store = useStore()
            const project_id = route.params.id
            const company = store.getters.getSelectedBoard
            const invitationData = computed(() => store.getters.getProjectInvitationData);
            const projectStatus = store.getters.getProjectStatus;
            let setParams = {
                check: true,
                project_id: project_id,
            };
            data.inputFormData = {
                identification: {
                    program_name: '',
                    project_name: '',
                    product_name: '',
                    program_number: '',
                    baseline: '',
                },
                framework: {
                    project_cost: ''
                }
            }

            const translations = computed(() => {
                return store.state.translations;  
            });

            data.setURL = vueConfig.Project.Setup.StartActivityEndPoint
            onMounted(async () => {
                await editFormData({url: data.setURL, params: setParams})
                .then(() => {
                    if(!data.inputFormData.identification && !data.inputFormData.framework) {
                        data.inputFormData = {
                            identification: {
                                program_name: '',
                                project_name: '',
                                product_name: '',
                                program_number: '',
                                baseline: '',
                            },
                            framework: {
                                project_cost: ''
                            }
                        }
                    }
                });
            });

            /** watch **/
            permissionResolver('identification', true);
            /** watch end **/

            function formAction(inputFormData) {
                inputFormData.project_id = project_id
                inputFormData.company_id = company.id
                storeFormData({url: data.setURL}, inputFormData)
            }

            return {
                data, formAction, invitationData, projectStatus, translations
            }
        }
    }
</script>

<style scoped>

</style>
